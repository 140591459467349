/* eslint-disable no-unneeded-ternary */
import { toast } from 'react-toastify';
export const getToken = () => {
  return localStorage.getItem('accessToken');
};
export const removeToken = () => {
  localStorage.clear();
};
export const getUsername = () => {
  return localStorage.getItem('username');
};
export const getfunctions = () => {
  return localStorage.getItem('functions');
};
export const getloginDuration = () => {
  const time = localStorage.getItem('loginDuration');
  const sec = time * 60000;
  return sec;
};
export const getFirstlogstatus = () => {
  return localStorage.getItem('firstlog');
};
export const getRole = () => {
  return localStorage.getItem('roles');
};
export const handleWarnAlert = (reason) => {
  toast.warn(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const handleDangerAlert = (reason) => {
  toast.error(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const unitCode = [
  {
    label: 'Rupees (₹)',
    value: '10101',
  },
  {
    label: 'Rupees (₹/ p.m)',
    value: '10102',
  },
  {
    // label: 'Percentage',
    label: '₹',
    value: '10103',
  },
  {
    label: 'No unit',
    value: '10104',
  },
];
export const matchUnitByCode = (code) => {
  const isExist = unitCode?.find((i) => i.value === code);
  if (isExist) return isExist.label;
  else return '';
};

export const deductionSymbolGetter = (val) => {
  if (val === true) return '-';
  else if (val === false) return '+';
  else return '';
};

export const isTypeNumber = (code) => {
  if (code === '10005' || code === '10002') return true;
  else return false;
};

export const inputFieldCodes = {
  text: '10001',
  number: '10002',
  boolean: '10003',
  select: '10004',
  float: '10005',
  date: '10006',
  dateTime: '10007',
};
export const getTypeypeOfTheCmponent = (type) => {
  if (type === inputFieldCodes.text) return 'Alpha-Numeric';
  else if (type === inputFieldCodes.number) return 'Numeric';
  if (type === inputFieldCodes.boolean) return 'Yes/No';
  if (type === inputFieldCodes.select) return 'Select';
  if (type === inputFieldCodes.float) return 'Numeric (Includes Decimal)';
  if (type === inputFieldCodes.date) return 'Date';
  if (type === inputFieldCodes.dateTime) return 'Date/Time';
};

export const selectComponentStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#f0f0f0',
    border: 0,
    height: 50.87,
    minHeight: 50.87,
    displa: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  placeholder: (base) => ({
    ...base,
    // color: 'red',
    fontSize: '0.8rem',
  }),
};
export const selectComponentErrorStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#f0f0f0',
    border: '1px solid red',
    height: 50.87,
    minHeight: 50.87,
    displa: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  placeholder: (base) => ({
    ...base,
    // color: 'red',
    fontSize: '0.8rem',
  }),
};
export const findRole = (roles) => {
  const role = localStorage.getItem('roles');
  const roleSplir = role.split(',');

  return roleSplir.some((val) => val === roles);
};
