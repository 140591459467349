import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getfunctions } from '../utils/utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        if (i !== 'func') this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    const functions = getfunctions();
    if (functions) {
      const f = functions.split(',');
      this.setState({ func: f });
    } else {
      this.setState({ func: [] });
    }
    this.onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          document.body.classList.toggle('sidebar-icon-only');
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          document.body.classList.toggle('sidebar-icon-only');
          el.classList.remove('hover-open');
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      if (i !== 'func') this.setState({ [i]: false });
    });
    const dropdownPaths = [
      { path: '/settings', state: 'settingsMenuOpen' },
      { path: '/company', state: 'companyMenuOpen' },
      { path: '/employee', state: 'employeeMenuOpen' },
      { path: '/roaster', state: 'roasterMenuOpen' },
      { path: '/leave', state: 'mangeMenuOpen' },
      { path: '/time', state: 'mangetimeMenuOpen' },
      { path: '/payrole', state: 'payroleMenuOpen' },
      { path: '/document', state: 'documentMenuOpen' },
      { path: '/role', state: 'roleMenuOpen' },
      { path: '/referral', state: 'referralMenuOpen' },
      { path: '/pharmacy', state: 'pharmacyMenuOpen' },
      { path: '/lab', state: 'labMenuOpen' },
      { path: '/skill', state: 'skillMenuOpen' },
      { path: '/coupons', state: 'couponMenuOpen' },
      // { path: '/subscription', state: 'subscriptionMenuOpen' },
      { path: '/patient', state: 'patientprofileMenu' },
      { path: '/cod', state: 'codMenu' },
      { path: '/banner', state: 'bannerMenu' },
      { path: '/resetpassword', state: 'restMenu' },
      { path: '/changepassword', state: 'passMenu' },
      { path: '/dash', state: 'dashMenu' },
      { path: '/report', state: 'reportMenu' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  getLinkAccess(funcName) {
    const { func } = this.state;
    return func && func.includes(funcName);
  }

  getLinkHeadAccess(links) {
    const { func } = this.state;
    return func && func.some((item) => links.includes(item));
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Home</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {/* ADMIN Portal */}
          {/* {this.getLinkHeadAccess(['SHOW_ADMIN_DASHBOARD', 'SHOW_FINANCE_DASHBOARD', 'SHOW_ANALYTIC_DASHBOARD']) && (
            <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.dashboardOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('dashboardOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Dashboard</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-settings menu-icon"></i>
              </div>
              <Collapse in={this.state.dashboardOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('SHOW_ADMIN_DASHBOARD') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/dashboard/adminDashboard') ? 'nav-link active' : 'nav-link'}
                        to="/dashboard/adminDashboard"
                      >
                        Admin Dashboard
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('SHOW_ADMIN_DASHBOARD') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/dashboard/analyticsDashboard') ? 'nav-link active' : 'nav-link'}
                        to="/dashboard/analyticsDashboard"
                      >
                        Analytics Dashboard
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* Admin Portal */}
          {/* {this.getLinkHeadAccess(['MANAGE_CATEGORY_TYPE', 'MANAGE_CATEGORY', 'MANAGE_SPECIALITY_ORDER']) && (
            <li className={this.isPathActive('/settings') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.settingsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('settingsMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Category</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-compare menu-icon"></i>
              </div>
              <Collapse in={this.state.settingsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_CATEGORY_TYPE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/settings/category-type') ? 'nav-link active' : 'nav-link'}
                        to="/settings/category-type"
                      >
                        Create Type
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_CATEGORY') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/settings/category') ? 'nav-link active' : 'nav-link'}
                        to="/settings/category"
                      >
                        Create Item
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SPECIALITY_ORDER') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/settings/category-order') ? 'nav-link active' : 'nav-link'}
                        to="/settings/category-order"
                      >
                        Item Order
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {this.getLinkHeadAccess(['MANAGE_COMPANY', 'MANAGE_BRANCH']) && (
            <li className={this.isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.companyMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('companyMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Hospital</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-hospital-building menu-icon"></i>
              </div>
              <Collapse in={this.state.companyMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_COMPANY') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/company/company') ? 'nav-link active' : 'nav-link'}
                        to="/company/company"
                      >
                        Create Hospital
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_BRANCH') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/company/branch') ? 'nav-link active' : 'nav-link'}
                        to="/company/branch"
                      >
                        Create Branch
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess([
            'MANAGE_SKILL_TYPES',
            'MANAGE_SKILL_LEVELS',
            'MANAGE_SKILL_SETS',
            'MANAGE_SKILL_MAPS',
          ]) && (
            <li className={this.isPathActive('/skill') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.skillMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('skillMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Competence</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-math-compass menu-icon"></i>
              </div>
              <Collapse in={this.state.skillMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_SKILL_TYPES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/types') ? 'nav-link active' : 'nav-link'}
                        to="/skill/types"
                      >
                        Types
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SKILL_LEVELS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/levels') ? 'nav-link active' : 'nav-link'}
                        to="/skill/levels"
                      >
                        Levels
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SKILL_SETS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/sets') ? 'nav-link active' : 'nav-link'}
                        to="/skill/sets"
                      >
                        Sets
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SKILL_MAPS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/maps') ? 'nav-link active' : 'nav-link'}
                        to="/skill/maps"
                      >
                        Mapping
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_EMPLOYEE']) && (
            <li className={this.isPathActive('/employee') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.employeeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('employeeMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Employment</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account menu-icon"></i>
              </div>
              <Collapse in={this.state.employeeMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/employee/manage-employee') ? 'nav-link active' : 'nav-link'}
                        to="/employee/manage-employee"
                      >
                        Manage Employee
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/employee/mapping') ? 'nav-link active' : 'nav-link'}
                        to="/employee/mapping"
                      >
                        Employee Reporting
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {/* {this.getLinkHeadAccess(['SHOW_ADMIN_DASHBOARD']) && (
            <li className={this.isPathActive('/cod') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.employeeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('codMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">COD</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-orbit menu-icon"></i>
              </div>
              <Collapse in={this.state.codMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/cod/approval') ? 'nav-link active' : 'nav-link'}
                        to="/cod/approval"
                      >
                        Assign Agent
                      </Link>
                    </li>
                  )}

                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/cod/orderstatus') ? 'nav-link active' : 'nav-link'}
                        to="/cod/orderstatus"
                      >
                        Order Status
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* {this.getLinkHeadAccess(['SHOW_ADMIN_DASHBOARD']) && (
            <li className={this.isPathActive('/banner') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.bannerMenu ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('bannerMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">Banners</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-xmpp menu-icon"></i>
              </div>
              <Collapse in={this.state.bannerMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('SHOW_ADMIN_DASHBOARD') && (
                    <li className="nav-item">
                      {' '}
                      <Link className={this.isPathActive('/banner') ? 'nav-link active' : 'nav-link'} to="/banner">
                        Banner
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('SHOW_ADMIN_DASHBOARD') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/view/banner') ? 'nav-link active' : 'nav-link'}
                        to="/view/banner"
                      >
                        View Banner
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* MANAGE_PATIENT_PROFILE */}
          {/* {this.getLinkHeadAccess(['MANAGE_EMPLOYEE']) && (
            <li className={this.isPathActive('/patient') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.patientprofileMenu ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('patientprofileMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">Patients</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account-card-details menu-icon"></i>
              </div>
              <Collapse in={this.state.patientprofileMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/patient/profile') ? 'nav-link active' : 'nav-link'}
                        to="/patient/profile"
                      >
                        Profile
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {this.getLinkHeadAccess(['MANAGE_ROLE_MANAGEMENT', 'MANAGE_ROLES', 'MANAGE_ROLE_MAPPING']) && (
            <li className={this.isPathActive('/role') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.roleMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('roleMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Roles</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account-edit menu-icon"></i>
              </div>
              <Collapse in={this.state.roleMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_ROLES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/role/roles') ? 'nav-link active' : 'nav-link'}
                        to="/role/roles"
                      >
                        View Roles
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_ROLE_MANAGEMENT') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/role/responsibility') ? 'nav-link active' : 'nav-link'}
                        to="/role/responsibility"
                      >
                        Add Responsibilities
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_ROLE_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/role/role-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/role/role-mapping"
                      >
                        Assign Role
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_REFERRAL_MAPPING', 'MANAGE_REFERRAL_REQUEST']) && (
            <li className={this.isPathActive('/referral') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.referralMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('referralMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Team</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account-multiple-plus menu-icon"></i>
              </div>
              <Collapse in={this.state.referralMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_REFERRAL_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/referral/CreateTeamOwner') ? 'nav-link active' : 'nav-link'}
                        to="/referral/CreateTeamOwner"
                      >
                        Create Team
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_REFERRAL_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/referral/manageTeam') ? 'nav-link active' : 'nav-link'}
                        to="/referral/manageTeam"
                      >
                        Manage Team
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_REFERRAL_REQUEST') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/referral/referralStatus') ? 'nav-link active' : 'nav-link'}
                        to="/referral/referralStatus"
                      >
                        Team Status
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess([
            'MANAGE_SHIFT_TIME',
            'MANAGE_SHIFT_MAPPING',
            'MANAGE_WEEK_OFF',
            'MANAGE_DOCTOR_CALENDER',
            'MANAGE_SLOT_SETTINGS',
            'MANAGE_LEAVE_SETTINGS',
          ]) && (
            <li className={this.isPathActive('/roaster') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.roasterMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('roasterMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Shift Roster</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-timetable menu-icon"></i>
              </div>
              <Collapse in={this.state.roasterMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_SHIFT_TIME') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/shift-time') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/shift-time"
                      >
                        Shift Time
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SHIFT_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/add-shift-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/add-shift-mapping"
                      >
                        Assign Shift
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SHIFT_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/shift-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/shift-mapping"
                      >
                        View Assigned Shift
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DOCTOR_CALENDER') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/doctor-calender') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/doctor-calender"
                      >
                        Doctor Calender
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_LEAVES', 'MANAGE_LEAVE_MASTERS']) && (
            <li className={this.isPathActive('/leave') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.mangeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('mangeMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Manage Absence</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-account-alert menu-icon"></i>
              </div>
              <Collapse in={this.state.mangeMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_LEAVE_MASTERS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/leavetype') ? 'nav-link active' : 'nav-link'}
                        to="/leave/leavetype"
                      >
                        Leave Masters
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LEAVE_MASTERS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/holiday') ? 'nav-link active' : 'nav-link'}
                        to="/leave/holiday"
                      >
                        Holiday Masters
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LEAVES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/addleave') ? 'nav-link active' : 'nav-link'}
                        to="/leave/addleave"
                      >
                        Apply Leave
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LEAVES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/approval') ? 'nav-link active' : 'nav-link'}
                        to="/leave/approval"
                      >
                        Leave Requests
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LEAVES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/histroy') ? 'nav-link active' : 'nav-link'}
                        to="/leave/histroy"
                      >
                        View Leave History
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_LEAVES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/teamleave/histroy') ? 'nav-link active' : 'nav-link'}
                        to="/leave/teamleave/histroy"
                      >
                        My Team Leave History
                      </Link>
                    </li>
                  )} */}
                  {/* {this.getLinkAccess('MANAGE_LEAVES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/compoff') ? 'nav-link active' : 'nav-link'}
                        to="/leave/compoff"
                      >
                        Comp off Request
                      </Link>
                    </li>
                  )} */}
                  {this.getLinkAccess('MANAGE_LEAVE_MASTERS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/hrteamleave') ? 'nav-link active' : 'nav-link'}
                        to="/leave/hrteamleave"
                      >
                        Team Leave History
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LEAVE_MASTERS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/createLeaveBalance') ? 'nav-link active' : 'nav-link'}
                        to="/leave/createLeaveBalance"
                      >
                        Create Leave Balance
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_LEAVE_MASTERS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/leavemap') ? 'nav-link active' : 'nav-link'}
                        to="/leave/leavemap"
                      >
                        Leave Mapping
                      </Link>
                    </li>
                  )} */}
                  {this.getLinkAccess('MANAGE_LEAVE_MASTERS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/allleave') ? 'nav-link active' : 'nav-link'}
                        to="/leave/allleave"
                      >
                        All leave Request
                      </Link>
                    </li>
                  )}

                  {this.getLinkAccess('MANAGE_LEAVES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/leave/balance') ? 'nav-link active' : 'nav-link'}
                        to="/leave/balance"
                      >
                        Leave Balance
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_ATTENDANCE', 'MANAGE_TIME_TRACKING']) && (
            <li className={this.isPathActive('/time') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.mangetimeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('mangetimeMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Manage Time</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-clock-fast menu-icon"></i>
              </div>
              <Collapse in={this.state.mangetimeMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_TIME_TRACKING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/time/track') ? 'nav-link active' : 'nav-link'}
                        to="/time/track"
                      >
                        Time Tracking
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_ATTENDANCE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/time/attendance') ? 'nav-link active' : 'nav-link'}
                        to="/time/attendance"
                      >
                        View Attendance
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DEPARTMENT_ATTENDANCE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/time/departmentattendance') ? 'nav-link active' : 'nav-link'}
                        to="/time/departmentattendance"
                      >
                        Department Attendances
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DEPARTMENT_ATTENDANCE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/time/multilogs') ? 'nav-link active' : 'nav-link'}
                        to="/time/multilogs"
                      >
                        Attendance Log
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_TIME_TRACKING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/time/attendance/upload') ? 'nav-link active' : 'nav-link'}
                        to="/time/attendance/upload"
                      >
                        Attendance Import
                      </Link>
                    </li>
                  )} */}
                </ul>
              </Collapse>
            </li>
          )}
          {/* {this.getLinkHeadAccess(['MANAGE_EMPLOYEE']) && (
            <li className={this.isPathActive('/report') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.reportMenu ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('reportMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">All Reports</span>

                <i className="menu-arrow"></i>

                <i className="mdi mdi-account menu-icon"></i>
              </div>

              <Collapse in={this.state.reportMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/report/breaktime') ? 'nav-link active' : 'nav-link'}
                        to="/report/breaktime"
                      >
                        Reports
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {this.getLinkHeadAccess(['MANAGE_PAYROLL']) && (
            <li className={this.isPathActive('/payrole') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.payroleMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('payroleMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Payroll</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-cash-multiple menu-icon"></i>
              </div>
              <Collapse in={this.state.payroleMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_PAYROLL') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/payrole/payrole-master') ? 'nav-link active' : 'nav-link'}
                        to="/payrole/payrole-master"
                      >
                        Salary Component Master
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_PAYROLL') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={
                          this.isPathActive('/payrole/payrole-screen-mapping') ? 'nav-link active' : 'nav-link'
                        }
                        to="/payrole/payrole-screen-mapping"
                      >
                        Screen Mapping
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_ATTENDANCE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/payrole/payrole-grade-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/payrole/payrole-grade-mapping"
                      >
                        Grade Mapping
                      </Link>
                    </li>
                  )} */}
                  {this.getLinkAccess('MANAGE_PAYROLL') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/payrole/payrole-component') ? 'nav-link active' : 'nav-link'}
                        to="/payrole/payrole-component"
                      >
                        Payroll Component
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_PAYROLL']) && (
            <li className={this.isPathActive('/document') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.documentMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('documentMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Document Preparation</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-file-document menu-icon"></i>
              </div>
              <Collapse in={this.state.documentMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_PAYROLL') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/document/appointmentletter') ? 'nav-link active' : 'nav-link'}
                        to="/document/appointmentletter"
                      >
                        Appointment Letter
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_PAYROLL') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/document/relievingletter') ? 'nav-link active' : 'nav-link'}
                        to="/document/relievingletter"
                      >
                        Relieving & Exp Letter
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_PAYROLL') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/document/offerletter') ? 'nav-link active' : 'nav-link'}
                        to="/document/offerletter"
                      >
                        Offer Letter
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {/* {this.getLinkHeadAccess(['MANAGE_COUPONS']) && (
            <li className={this.isPathActive('/coupons') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.couponMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('couponMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Coupons</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-ticket-percent menu-icon"></i>
              </div>
              <Collapse in={this.state.couponMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_COUPONS') && (
                    <li className="nav-item">
                      {' '}
                      <Link className={this.isPathActive('/coupons') ? 'nav-link active' : 'nav-link'} to="/coupons">
                        Coupons
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* {this.getLinkHeadAccess(['MANAGE_COUPONS']) && (
            <li className={this.isPathActive('/subscription') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.subscriptionMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('subscriptionMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Subscription</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-diamond menu-icon"></i>
              </div>
              <Collapse in={this.state.subscriptionMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_COUPONS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={
                          this.isPathActive('/subscription/subscriptionModule') ? 'nav-link active' : 'nav-link'
                        }
                        to="/subscription/subscriptionModule"
                      >
                        Subscription Module
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* <li className={this.isPathActive('/subscription') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/subscription">
              <span className="menu-title">Subscription Module</span>
              <i className="mdi mdi-diamond menu-icon"></i>
            </Link>
          </li> */}
          {this.getLinkHeadAccess(['MANAGE_ROLE_MANAGEMENT', 'MANAGE_ROLES', 'MANAGE_ROLE_MAPPING']) && (
            <li className={this.isPathActive('/announcement') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/announcement">
                <span className="menu-title">Announcement</span>
                <i className="mdi mdi-access-point menu-icon"></i>
              </Link>
            </li>
          )}
          <li className={this.isPathActive('/changepassword') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/changepassword">
              <span className="menu-title">Change Password</span>
              <i className="mdi mdi-lock menu-icon"></i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
  func: PropTypes.array,
};
export default withRouter(Sidebar);
