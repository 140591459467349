import { combineReducers } from 'redux';
import globalReducer from './globalSetupReducer';
import userReducer from './userReducers';
import masterReducer from './masterReducer';
import companyReducer from './companyReducer';
import { roasterReducer } from './roasterReducer';
import { pharmaReducer } from './pharmaReducer';
import { labReducer } from './labReducer';
import { skillsReducer } from './skillsReducer';
import { couponReducer } from './couponReducer';
import { patientProfileReducer } from './patientProfileReducer';
import { homecollectionReducer } from './homecollectionReducer';
import { teamReducer } from './teamReducer';
import { timeTrackingReducer } from './timeTrackingReducer';
import { payrollReducer } from './payrollReducer';

export default combineReducers({
  global: globalReducer,
  user: userReducer,
  master: masterReducer,
  hospital: companyReducer,
  roaster: roasterReducer,
  pharmacy: pharmaReducer,
  laboratory: labReducer,
  skills: skillsReducer,
  coupons: couponReducer,
  patientProfile: patientProfileReducer,
  homecollection: homecollectionReducer,
  team: teamReducer,
  timeTracking: timeTrackingReducer,
  payroll: payrollReducer,
});
