export const masterReducer = (state = {}, action, name) => {
  switch (action.type) {
    case 'GET_CATEGORY_TYPE':
      return {
        ...state,
        categoryTypes: action.payload,
      };
    case 'GET_CATEGORY':
      return {
        ...state,
        [action.name]: action.payload,
      };
    case 'GET_CATEGORY_ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'GET_CATEGORY_PARENT':
      return {
        ...state,
        [action.name]: action.payload,
      };
    case 'GET_PARENTCODE':
      return {
        ...state,
        parentcode: action.payload,
      };
    case 'GET_CATEGORYGEN':
      return {
        ...state,
        categorygen: action.payload,
      };

    case 'GET_CATEGORY1':
      return {
        ...state,
        category: action.payload,
      };
    case 'GET_BANNER':
      return {
        ...state,
        banner: action.payload,
      };
    case 'GET_ORE':
      return {
        ...state,
        order: action.payload,
      };
    case 'GET_APP':
      return {
        ...state,
        approve: action.payload,
      };
    case 'GET_TEST_DATA':
      return {
        ...state,
        test: action.payload,
      };
    case 'GET_PHAR_NAME':
      return {
        ...state,
        pharmacyname: action.payload,
      };
    case 'GET_PACK_DATA':
      return {
        ...state,
        pack: action.payload,
      };
    case 'GET_DRUG_DATA':
      return {
        ...state,
        drug: action.payload,
      };
    case 'GET_DOC_DATA':
      return {
        ...state,
        docfind: action.payload,
      };
    case 'GET_DOCUMENT_DATA':
      return {
        ...state,
        document: action.payload,
      };
    case 'GET_EXEC':
      return {
        ...state,
        exec: action.payload,
      };
    default:
      return state;
  }
};
export default masterReducer;
