import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import RBTableNext from '../../components/RBTableNext';
import { consultationDataList, consultationStatus } from '../../services/services';

const AnalyticsDashboard = () => {
  // const dispatch = useDispatch();
  const [consultationStatusData, setConsulationStatusData] = useState([]);
  const [consultationListData, setConsulationListData] = useState([]);
  const [consultationType, setConsultationType] = React.useState('');
  useEffect(() => {
    const getChartData = async () => {
      const res = await consultationStatus();
      if (res) {
        const resultData = res.flat();
        setConsulationStatusData(resultData);
      }
    };
    getChartData();
    const getDataList = async () => {
      const res = await consultationDataList('scheduled');
      setConsultationType('scheduled');
      if (res) {
        const resultData = res.flat();
        setConsulationListData(resultData);
      }
    };
    getDataList();
  }, []);
  const handleChange = async (event) => {
    setConsultationType(event.target.value);
    const res = await consultationDataList(event.target.value);
    if (res) {
      const resultData = res.flat();
      setConsulationListData(resultData);
    }
  };
  const dateFormatter = (cell, row) => {
    return <div>{moment(row.consultationDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>;
  };
  const actionsFormatter = (cell, row) => {
    return <div>{moment(row.timeSlot, 'hh:mm:ss').format('hh:mm A')}</div>;
  };
  /* const renderTooltip = (props) => (
    <Tip id="button-tooltip" {...props}>
      Download Graph
    </Tip>
  ); */
  /* const [getAreaPng, { ref: areaRef }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    dispatch(loadingAction(true));
    const png = await getAreaPng();
    dispatch(loadingAction(false));
    if (png) {
      console.log(png, 'png');
      saveAs(png, ' consultations.png');
    }
  }, [getAreaPng]); */
  const columns = [
    { dataField: 'patientId', text: 'MRD No', sort: true },
    { dataField: 'patientName', text: 'Patient Name', sort: true },
    { dataField: 'doctorName', text: 'Doctor Name', sort: true },
    { dataField: 'consultationDate', text: 'Date', sort: true, formatter: dateFormatter },
    { dataField: 'timeSlot', text: 'Time Slot', sort: true, formatter: actionsFormatter },
    { dataField: 'status', text: 'Status', sort: true },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ width: '100%', overflow: 'visible !important' }}>
          <CardHeader
            title="Analytics Dashboard"
            action={
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={consultationType}
                  label="Type"
                  onChange={handleChange}
                  size="small"
                >
                  <MenuItem value="scheduled">Scheduled</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="followup">Followup</MenuItem>
                  <MenuItem value="missed">Missed Consultation</MenuItem>
                </Select>
              </FormControl>
            }
          />
          <CardContent>
            <RBTableNext data={consultationListData} columns={columns} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <div className="d-flex justify-content-between align-items-center bg-light">
            <div>
              <CardHeader title="Consultations" />
            </div>
            {/* <div>
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <i
                  className="mdi mdi-download p-1 px-2 rounded shadow mr-2 page-title-icon bg-gradient-live text-white cursor-pointer"
                  onClick={() => handleDownload()}
                ></i>
              </OverlayTrigger>
            </div> */}
          </div>
          <LoadingOverlay active={false} spinner text="Loading your content...">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={consultationStatusData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" name="Today Consultations" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="value" name="" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </LoadingOverlay>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AnalyticsDashboard;
