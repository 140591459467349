import {
  login,
  getCategoryList,
  getHospital,
  getBranch,
  getCity,
  getState,
  getEmployee,
  listEmployee,
  getSlots,
  getSchedule,
  getReferral,
  getReferralMappings,
  getReferralRequest,
  getShifttime,
  getShiftTimeMap,
  getPharmaCategory,
  getAdminCategoryType,
  getAdminCategory,
  getLabData,
  getSubgroupByGroupId,
  getSkillsData,
  getCouponData,
  getRolesFunctionalityList,
  getRolesList,
  getPatientProfileData,
  getSlotDuration,
  getExecutiveByPincode,
  getLabAvailableSlotsByDate,
  getLabBookingDetails,
  getdashborad,
  postdashboard,
  patientRegistrationDataService,
  countofdoc,
  countappoint,
  countlab,
  numberofpa,
  profileDetails,
  getTeamOwnersService,
  getEmployeeWithFilter,
  getTeamName,
  getEmpnamebyTeam,
  getorderStatus,
  getapproveddata,
  getLabExecutive,
  viewShift,
  getbanners,
  getpack,
  gettest1,
  pharmacyName,
  drugService,
  getSubscriptionService,
  masterleavecode,
  getholiday,
  finddoc,
  getDrugs,
  getTimeTrackingService,
  getAttendanceService,
  getDepAttendanceService,
  getAllPayrollComponentService,
  createSalaryService,
  editSalaryService,
  getAllPayrollConstantsService,
  getReferralRequestByEmp,
  getcompoff,
  getparentcode,
  getCategoryempmapping,
  Reportingtoemployee,
  getempifo,
  getemppagenation,
  getempifoforonedata,
  getdocuments,
} from '../../services/services';
export const loadingAction = (loading) => async (dispatch, getState) => {
  dispatch({
    type: 'GLOBLE_OVERLOADING',
    payload: loading,
  });
};
export const loadingPage = (loadingpage) => async (dispatch, getState) => {
  dispatch({
    type: 'GLOBLE_LOADING',
    payload: loadingpage,
  });
};
export const submitButtonAction = (btnType) => async (dispatch, getState) => {
  dispatch({
    type: 'SUBMIT_BUTTON',
    payload: btnType,
  });
};
export const loginAction = (d, props) => async (dispatch, getState) => {
  dispatch(loadingAction(true));
  const res = await login(d, dispatch, props);

  if (res) {
    dispatch({
      type: 'LOGIN',
      payload: res.data,
    });
    if (res.status === 200 && res.data.firstLogin === false) {
      props.history.push('/dashboard');
    }
    dispatch(loadingAction(false));
  }
};

export const getCategory = (_c) => async (dispatch) => {
  dispatch(loadingAction(true));
  let categoryList = [];
  if (_c) {
    categoryList = _c;
  } else {
    categoryList = [
      'Title',
      'BloodGroup',
      'EmployeeType',
      'JobType',
      'SalaryType',
      'EducationLevel',
      'Gender',
      'Designation',
      'Specialists',
      'CourseList',
      'Department',
      'Language',
      'Grade',
    ];
  }

  categoryList.map(async (c) => {
    const data = await getCategoryList(c);
    if (data && data.items && data.items.length > 0) {
      dispatch({
        type: 'GET_CATEGORY',
        payload: data,
        name: c.toLowerCase().replace(/\s/g, ''),
      });
    }
    dispatch(loadingAction(false));
  });
};

export const getHospitalData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getHospital(props);
  switch (data?.code) {
    case '200':
      if (data && data.data && data.data.length > 0) {
        dispatch({
          type: 'GET_HOSPITAL_DATA',
          payload: data.data,
        });
      }
      dispatch(loadingAction(false));
      break;
    case 401:
      dispatch({
        type: 'SET_TOKEN_VALID',
        payload: false,
      });
      dispatch(loadingAction(false));
      break;
    default:
      break;
  }
};
export const getBranchData = (props) => async (dispatch) => {
  const data = await getBranch(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_BRANCH_DATA',
      payload: data,
    });
  }
};
export const getProfiledata = (d, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await profileDetails(d, props);

  // if (data && data.length > 0) {
  dispatch({
    type: 'GET_PRO_DATA',
    payload: data?.data,
  });
  dispatch(loadingAction(false));
  // } else {
  //   dispatch({
  //     type: 'GET_PRO_DATA',
  //     payload: [],
  //   });
  // }
};
export const getStateData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getState(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_STATE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_STATE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getDrug = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getDrugs(data1);
  if (data && data.items.length > 0) {
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const docdetails = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await finddoc(data1);

  dispatch({
    type: 'GET_DOC_DATA',
    payload: data,
  });
  dispatch(loadingAction(false));
};
export const getCityData = (state) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCity(state);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_CITY_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CITY_DATA',
      payload: {},
    });
    dispatch(loadingAction(false));
  }
};
export const getEmployeeData = (status, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getempifoforonedata(status, props);
  if (data && data?.items?.length > 0) {
    dispatch({
      type: 'GET_Employee_DATA',
      payload: data?.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_Employee_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getEmployeeDataPage = (status, datass2, pageNumber, itemPerPage, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getemppagenation(status, datass2, pageNumber, itemPerPage, props);
  if (data && data?.items.length > 0) {
    dispatch({
      type: 'GET_EmployeePage_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_EmployeePage_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getdocumentsdata = (day, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdocuments(day, props);
  if (data && data?.length > 0) {
    dispatch({
      type: 'GET_DOCUMENT_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DOCUMENT_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getEmpalldata = (methed, value) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getempifo(methed, value);
  if (data && data.items.length > 0) {
    dispatch({
      type: 'GET_Employee_Info',
      payload: data?.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_Employee_Info',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getaddemployeelist = (methed, value) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getempifo(methed, value);
  if (data && data.items.length > 0) {
    dispatch({
      type: 'GET_Employee_Add',
      payload: data?.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_Employee_Add',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReportingtoemployeedata = (data1, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await Reportingtoemployee(data1, props);
  console.log(data);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REPORTINGTO',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REPORTINGTO',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getCategoryparentname = (props, categoryType, name, status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCategoryempmapping(categoryType, name, status, props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_CATEGORY_PARENT',
      payload: data.data,
      name: categoryType,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CATEGORY_PARENT',
      payload: [],
      name: categoryType,
    });
    dispatch(loadingAction(false));
  }
};
export const getParentcodeName = (props, id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getparentcode(id);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data) {
    dispatch({
      type: 'GET_PARENTCODE',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PARENTCODE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getEmployeeseconddata = (status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getEmployee(status);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_EMP_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_EMP_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getEmployeeDataByFilter = (status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getEmployeeWithFilter(status);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_Employee_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_Employee_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getDoctorData = (d, roles, props, sp) => async (dispatch) => {
  const filter = {
    searchCriteria: {
      role: roles,
      empStatus: true,
      departmentName: d,
      speciality: sp,
    },
  };
  dispatch(loadingAction(true));
  const data = await listEmployee(filter, props);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_DOCTOR_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DOCTOR_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSlotData = (doctorId, date, props) => async (dispatch) => {
  if (doctorId && date) {
    dispatch(loadingAction(true));
    const data = await getSlots(doctorId, date, props);
    if (data && data.length > 0) {
      dispatch({
        type: 'GET_DOCTOR_SLOT_DATA',
        payload: data,
      });
      dispatch(loadingAction(false));
    } else {
      dispatch({
        type: 'GET_DOCTOR_SLOT_DATA',
        payload: [],
      });
      dispatch(loadingAction(false));
    }
  } else {
    dispatch({
      type: 'GET_DOCTOR_SLOT_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSlotDurationData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getSlotDuration();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_DOCTOR_SLOT_DURATION_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DOCTOR_SLOT_DURATION_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getScheduleData = (doctorId, props) => async (dispatch) => {
  if (doctorId) {
    dispatch(loadingAction(true));
    const data = await getSchedule(doctorId, props);
    if (data && data.length >= 0) {
      dispatch({
        type: 'GET_DOCTOR_SCHEDULE_DATA',
        payload: data,
      });
      dispatch(loadingAction(false));
    } else {
      dispatch({
        type: 'GET_DOCTOR_SCHEDULE_DATA',
        payload: [],
      });
      dispatch(loadingAction(false));
    }
  } else {
    dispatch({
      type: 'GET_DOCTOR_SCHEDULE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getRoleFunctionsData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getRolesFunctionalityList(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ROLE_FUNCTIONS_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ROLE_FUNCTIONS_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getRoleListData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getRolesList(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ROLE_LIST_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ROLE_LIST_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferral();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getcompoffleave = (meth, data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getcompoff(meth, data1);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_COMPOFF_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COMPOFF_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralMappingData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferralMappings();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_MAPPING_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_MAPPING_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralRequestData = (d, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferralRequest(d, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_REQUEST_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_REQUEST_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralRequestDataByEmployee = (d, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferralRequestByEmp(d, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_REQUEST_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_REQUEST_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getShiftTimeData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getShifttime(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_SHIFTTIME_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SHIFTTIME_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getorder = (id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getorderStatus(id);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ORE',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ORE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getapproved = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getapproveddata(props);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_APP',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_APP',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getExecutive = (id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getLabExecutive(id);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_EXEC',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_EXEC',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getShiftTimeMapData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getShiftTimeMap();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_SHIFTTIMEMAP_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SHIFTTIMEMAP_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getDocterTeam = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTeamName(props);
  console.log(data);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_TEAM_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TEAM_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getDocNamebyemp = (id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getEmpnamebyTeam(id, props);
  console.log(data);
  console.log(id);
  if (data) {
    dispatch({
      type: 'GET_EMPNAME_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_EMPNAME_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const viewShiftdata = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await viewShift(props);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_VIEWSHIFT_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_VIEWSHIFT_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const allholiday = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getholiday();
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_HOLIDAY_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_HOLIDAY_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getPharmaCategoryData = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getPharmaCategory(path);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: data,
      path: path || 'drug',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: [],
      path: path || 'drug',
    });
    dispatch(loadingAction(false));
  }
};
export const getAdminCategoryTypeData = (props, status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategoryType(status, props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_CATEGORY_TYPE',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CATEGORY_TYPE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAdminCategoryData = (props, categoryType, status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategory(categoryType, status);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_CATEGORY',
      payload: data.data,
      name: categoryType,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CATEGORY',
      payload: [],
      name: categoryType,
    });
    dispatch(loadingAction(false));
  }
};

export const getLabAllData = (props, path, service) => async (dispatch) => {
  console.log(service, 'fvsdvsdv');
  let pathName = path;
  console.log(pathName, 'pathName');
  if (service === 'packages') {
    pathName = 'packageList';
  } else if (service === 'laboratory-test-mappings') {
    pathName = 'labMappingList';
  } else {
    pathName = path;
  }
  if (pathName === 'test-samples') {
    pathName = 'testSamples';
  }
  console.log(pathName, 'pathName');
  // if (service === 'laboratory-test-mappings') pathName = 'labMappingList';
  dispatch(loadingAction(true));
  const data = await getLabData(path, service, props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: data.data,
      path: pathName || 'lab',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: [],
      path: pathName || 'lab',
    });
    dispatch(loadingAction(false));
  }
};
export const createDrugAction = (methord, data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (id === 'get') {
    const url = 'all/list/';
    const resData = await dispatch(drugService(methord, data, '', props, url));
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: resData,
      name: 'drug',
    });
  } else if (id === 'post') {
    await dispatch(drugService(methord, data, '', props));
  } else {
    if (methord === 'GET') {
      const res = await dispatch(drugService(methord, data, id, props));
      if (res) {
        console.log(res, 'edit res');
        dispatch(loadingAction(false));
        return res;
      }
    } else {
      await dispatch(drugService(methord, data, id, props));
    }
  }
  dispatch(loadingAction(false));
};
export const getAllSubscriptions = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const resData = await dispatch(getSubscriptionService());
  dispatch({
    type: 'GET_SUBSCRIPTION_DATA',
    payload: resData,
  });
  dispatch(loadingAction(false));
};
export const getLabAvailableSlotsData = (props, path, req) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getLabAvailableSlotsByDate(path, req);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.code === '200') {
    dispatch({
      type: 'GET_LAB_AVAIL_SLOTS_DATE',
      payload: data?.data?.rules,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_AVAIL_SLOTS_DATE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSubgroupByGroupIdData = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getSubgroupByGroupId(path);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_SUBGROUP_BY_GROUP',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SUBGROUP_BY_GROUP',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getSkills = (props, path) => async (dispatch) => {
  const pathName = path;
  dispatch(loadingAction(true));
  const data = await getSkillsData(path, props);
  console.log(data, 'data');
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: data.data,
      path: pathName || 'levels',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: [],
      path: pathName || 'levels',
    });
    dispatch(loadingAction(false));
  }
};

export const getSkillTypes = (props, path, status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategory(path, status, props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: data.data.items,
      path: 'types',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: [],
      path: 'types',
    });
    dispatch(loadingAction(false));
  }
};

export const getAllEmployeeData = (d, f, props) => async (dispatch, getState) => {
  let roles = [];

  if (Array.isArray(getState().hospital.roleList)) {
    roles = getState().hospital.roleList.map((role) => role.roleName);
  }

  const filter = {
    searchCriteria: {
      role: roles,
      empStatus: true,
      departmentName: d,
      designationName: f,
    },
  };
  dispatch(loadingAction(true));
  const { data } = await listEmployee(filter, props);

  if (data && data.items && data.items.length > 0) {
    dispatch({
      type: 'GET_ALL_EMPLOYEES',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ALL_EMPLOYEES',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getCoupons = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCouponData();
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: data.data,
      path: 'coupon',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: [],
      path: 'coupon',
    });
    dispatch(loadingAction(false));
  }
};
export const getleavemaster = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await masterleavecode(props);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_LEAVEMASTER_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LEAVEMASTER_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getProductsCoupons = (props, path) => async (dispatch) => {
  let allProducts = [];

  for (const item of path) {
    dispatch(loadingAction(true));
    const data = await getLabData(item);
    if (data && data.code === 401) {
      props.history.push({
        pathname: '/login',
        state: {},
      });
      dispatch(loadingAction(false));
      break;
    }
    if (data && data.data && data.data.length > 0) {
      let conData = [];
      if (item === 'subgroups') {
        conData = data.data.map((item) => {
          return {
            referenceId: item.subGroupId,
            name: item.subGroupName,
            type: 'SUBGROUP',
          };
        });
        dispatch(loadingAction(false));
      }
      if (item === 'packages') {
        conData = data.data.map((item) => {
          return {
            referenceId: item.packageId,
            name: item.packageName,
            type: 'PACKAGE',
          };
        });
        dispatch(loadingAction(false));
      }
      if (item === 'tests') {
        conData = data.data.map((item) => {
          return {
            referenceId: item.testId,
            name: item.testName,
            type: 'TEST',
          };
        });
        dispatch(loadingAction(false));
      }
      allProducts = allProducts.concat(conData);
    }
  }

  allProducts = allProducts.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

  if (allProducts.length > 0) {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: allProducts,
      path: 'products',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: [],
      path: 'products',
    });
    dispatch(loadingAction(false));
  }
};

export const getPatientProfile = (props, parameter) => async (dispatch) => {
  const parameters = {
    itemsPerPage: parameter?.itemsPerPage || 0,
    pageNumber: parameter?.pageNumber || 1,
    searchCriteria: {},
  };

  if (parameter.patientId) {
    parameters.searchCriteria = {
      patientId: parameter.patientId || '',
    };
  }
  dispatch(loadingAction(true));
  const data = await getPatientProfileData(parameters);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_PATIENTPROFILE_DATA',
      payload: data.data,
      path: 'profiles',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PATIENTPROFILE_DATA',
      payload: [],
      path: 'profiles',
    });
    dispatch(loadingAction(false));
  }
};
export const getLabBookingDetailsData = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getLabBookingDetails(path);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.code === '200' && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_LAB_DETAILS_BY_BOOKINGID',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_DETAILS_BY_BOOKINGID',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getLabExecutiveData = (roles, labId, props) => async (dispatch) => {
  const filter = {
    searchCriteria: {
      role: roles,
      empStatus: true,
      labId: labId,
    },
  };
  dispatch(loadingAction(true));
  const data = await listEmployee(filter);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_LAB_EXECUTIVE_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_EXECUTIVE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getLabExecutiveDataByPincode = (path, labId, pincode, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getExecutiveByPincode(path, labId, pincode);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_ASSIGN_EXECUTIVE_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ASSIGN_EXECUTIVE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const dateObjToString = (dateString) => {
  return `${new Date(dateString).getFullYear()}-${`0${new Date(dateString).getMonth() + 1}`.slice(-2)}-${`0${new Date(
    dateString,
  ).getDate()}`.slice(-2)}`;
};
export const getdashboarddata = (data1, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1, props);
  if (data) {
    dispatch({
      type: 'GET_DASH_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};
export const getdashboarddata1 = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_DASH1_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};

export const getdashboardcitydata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_DASHCITY_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};
export const adgetdashboarddata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_ADDASH_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};
export const adgetdashboarddata1 = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_ADDASH1_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};

export const adgetdashboardcitydata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_ADDASHCITY_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};
export const getdashboardcitypin = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_DASHCITYPIN_DATA',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};
export const postdashboarddata = (day) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await postdashboard(day);
  if (data) {
    dispatch({
      type: 'GET_POSTDASH_DATA',
      payload: data.data.items,
    });
  }
  dispatch(loadingAction(false));
};
export const getCategorydata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCategoryList(data1);
  if (data) {
    dispatch({
      type: 'GET_CATEGORY1',
      payload: data.items,
    });
  }
  dispatch(loadingAction(false));
};
export const getbannertype = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getbanners(data1);
  if (data) {
    dispatch({
      type: 'GET_BANNER',
      payload: data.items,
    });
  }
  dispatch(loadingAction(false));
};
export const getCategorygenderdata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCategoryList(data1);
  if (data) {
    dispatch({
      type: 'GET_CATEGORYGEN',
      payload: data.items,
    });
  }
  dispatch(loadingAction(false));
};
export const patientRegistrationData = (path, data1) => async (dispatch) => {
  dispatch({
    type: 'DASHBOARDPATIENTREGISTRATION_LOADINGSCREEN',
    payload: true,
  });
  dispatch(loadingAction(true));
  const data = await patientRegistrationDataService(path, data1);
  if (data) {
    dispatch({
      type: 'DASHBOARD_PATIENT_REGISTRATION',
      payload: data.data,
    });
    dispatch({
      type: 'DASHBOARDPATIENTREGISTRATION_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'DASHBOARDPATIENTREGISTRATION_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  }
};
export const countofdde = (path, data1) => async (dispatch) => {
  dispatch({
    type: 'DASHBOARDAPPOINTMENTSPECALITY_LOADINGSCREEN',
    payload: true,
  });
  dispatch(loadingAction(true));
  const data = await countofdoc(path, data1);
  if (data) {
    dispatch({
      type: 'GET_COUNTDATA',
      payload: data.data,
    });
    dispatch({
      type: 'DASHBOARDAPPOINTMENTSPECALITY_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'DASHBOARDAPPOINTMENTSPECALITY_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  }
};
export const countofappointment = (path, data1) => async (dispatch) => {
  dispatch({
    type: 'DASHBOARDAPPOINTMENT_LOADINGSCREEN',
    payload: true,
  });
  dispatch(loadingAction(true));
  const data = await countappoint(path, data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_COUNTAPPOINTDATA',
      payload: data.data,
    });
    dispatch({
      type: 'DASHBOARDAPPOINTMENT_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'DASHBOARDAPPOINTMENT_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  }
};
export const countoflabstatus = (path, data1) => async (dispatch) => {
  dispatch({
    type: 'DASHBOARDLABCOUNT_LOADINGSCREEN',
    payload: true,
  });
  dispatch(loadingAction(true));
  const data = await countlab(path, data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_COUNTLABDATA',
      payload: data.data,
    });
    dispatch({
      type: 'DASHBOARDLABCOUNT_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'DASHBOARDLABCOUNT_LOADINGSCREEN',
      payload: false,
    });
    dispatch(loadingAction(false));
  }
};
export const numberofpatient = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await numberofpa(data1);
  if (data) {
    dispatch({
      type: 'GET_PATIENTCOUNT',
      payload: data.data,
    });
  }
  dispatch(loadingAction(false));
};
export const getTeamOwnersData = (status, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTeamOwnersService(status, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_TeamOwners_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TeamOwners_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getPackData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getpack();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_PACK_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PACK_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getTestData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await gettest1();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_TEST_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TEST_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getpharmaName = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await pharmacyName();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_PHAR_NAME',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GGET_PHAR_NAME',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getTimeTrackingDateAction = (empId, start, end, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTimeTrackingService(empId, start, end, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_TIMETRACKING_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TIMETRACKING_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getTimeTrackingDate = (empId, start, end, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTimeTrackingService(empId, start, end, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_TRACK_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TRACK_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAttendanceByEmpAction = (empId, date, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAttendanceService(empId, date, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ATTENDANCE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ATTENDANCE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAttendanceByDepAction = (dep, startDate, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getDepAttendanceService(dep, startDate, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_DEPARTMENT_ATTENDANCE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DEPARTMENT_ATTENDANCE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAllPayrollComponentAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAllPayrollComponentService(props);
  if (data && data.items && Array.isArray(data.items) && data.items.length > 0) {
    dispatch({
      type: 'GET_ALLPAYROLL_COMPONENTS',
      payload: data.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ALLPAYROLL_COMPONENTS',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAllPayrollConstantsAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAllPayrollConstantsService(props);
  if (data) {
    dispatch({
      type: 'GET_ALLPAYROLL_CONSTANTS',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ALLPAYROLL_CONSTANTS',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const createSalaryComponentAction = (data, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const res = await createSalaryService(data, props);
  dispatch(loadingAction(false));
  return res;
};
export const editSalaryComponentAction = (data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const res = await editSalaryService(data, id, props);
  dispatch(loadingAction(false));
  return res;
};
