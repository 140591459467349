export const payrollReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ALLPAYROLL_COMPONENTS':
      return {
        ...state,
        payrollComponents: action.payload,
      };
    case 'GET_ALLPAYROLL_CONSTANTS':
      return {
        ...state,
        payrollConstants: action.payload,
      };
    default:
      return state;
  }
};
export default payrollReducer;
