/* eslint-disable react/prop-types */
// import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDispatch } from 'react-redux';

import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { fileAttachmentDownload } from '../services/services';
// import "./customizedComponents.css";
const FilePreview = ({ open, fileType, signedUrl, closePreview }) => {
  const dispatch = useDispatch();
  // const LEFT = '25%';
  return (
    <>
      {' '}
      {(open && fileType.includes('pdf')) || fileType.includes('image') ? (
        <div
          style={{
            position: 'fixed',
            top: 70,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#262626',
            zIndex: 10000,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'end', padding: '10px' }}>
            {fileType.includes('image') ? (
              <button
                style={{
                  fontSize: 10,
                  border: 'none',
                  background: '#262626',
                  float: 'right',
                  cursor: 'pointer',
                }}
                type="button"
                onClick={() =>
                  dispatch(
                    fileAttachmentDownload(
                      signedUrl,
                      fileType,
                      // `relieving_letter_${selectedRow?.firstName}`,
                      // props,
                    ),
                  )
                }
              >
                <FileDownloadIcon sx={{ color: 'white' }} />
              </button>
            ) : null}
            <button
              type="button"
              style={{
                fontSize: 10,
                border: 'none',
                background: '#262626',
                float: 'right',
                cursor: 'pointer',
              }}
              onClick={() => closePreview()}
            >
              <HighlightOffSharpIcon sx={{ color: 'white' }} />
            </button>
          </div>
          <iframe src={signedUrl} style={{ width: '100%', height: '100%' }}></iframe>{' '}
        </div>
      ) : open && fileType.includes('image') ? (
        <Lightbox
          enableZoom={true}
          mainSrc={signedUrl}
          onCloseRequest={() => closePreview()}
          clickOutsideToClose={false}
        />
      ) : null}
    </>
  );
};
export default FilePreview;
