import React, { useEffect, useRef } from 'react';
import { recordOfLoginandLogout } from '../../services/services';
import { getToken } from '../../utils/utils';

function RecordDate(props) {
  const loginRef = useRef();
  const logandout = async () => {
    const token = getToken();
    if (token) {
      const data = {
        entryType: 'logout',
        empId: localStorage.getItem('empId'),
      };
      const recordInfo = await recordOfLoginandLogout(data, props);
      console.log(recordInfo);
      if (recordInfo) {
        setTimeout(async () => {
          const data = {
            entryType: 'login',
            empId: localStorage.getItem('empId'),
          };
          await recordOfLoginandLogout(data, props);
        }, 2000);
      }
    } else {
      console.log('clear');
      clearInterval(loginRef.current);
    }
  };
  useEffect(() => {
    loginRef.current = setInterval(() => {
      logandout();
    }, 300000);
    return () => {
      console.log('cleardats');
      clearInterval(loginRef.current);
    };
  }, []);
  return <div></div>;
}

export default RecordDate;
